import styled from "@emotion/styled";
import {keyframes} from "@emotion/core";

export const enter_from_top = keyframes` 
  0% {
    transform: scaleY(0.4);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
`;

export const enter_from_bottom = keyframes` 
  0% {
    transform: scaleY(0.4);
    transform-origin:  0% 100%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 100%;
  }
`;

export const exit_from_top = keyframes` 
  0% {
    //transform: height 500px;
    height: 500px;

  }
  100% {
    height: 1px;
  }
`;

export const exit_from_top_ok = keyframes` 
  0% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
  }
`;

export const blur_in = keyframes`
  0% {
    -webkit-filter: blur(6px);
    filter: blur(6px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
`;

export const blur_out = keyframes`
  0% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-filter: blur(6px);
    filter: blur(6px);
    opacity: 0;
  }
`;


export const Name = styled.div`
  font-size: 35px;
  z-index: 30;
  cursor: pointer;
  margin: 25px;
  top: 0;
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  font-weight: 500;

  @media(max-width: 520px){
    margin: 15px;
    line-height: 35px;
  }


  :hover{
    color: #420072;
    cursor: pointer;
  }
`;

export const MenuItems = [
  {
    /*
    title: "Projects",
    link: "github.com"
    */
  },
  {
    title: "About",
    link: "/about"
  },
  {
    /*
    title: "Dashboard",
    link: "/dashboard"
    */
  }
];

export const ContactItems = [
 {
    title: "GitHub",
    link: "https://github.com/deak-s/"
  },
  {
    title: "LinkedIn",
    link: "https://www.linkedin.com/in/sophia-deak/"
  }
];



import React from 'react'
//import { Link } from 'gatsby'

//import Layout from '../components/layout'

import leaf from "./leaf.jpg"
import HomeMenu from "src/components/HomePage/HomeMenu";

import Helmet from 'react-helmet'
import SEO from "src/components/seo"

import styled from "@emotion/styled";


const IndexPage = () => (
  <div>
  <SEO/>
  <Helmet>
  </Helmet>
  <HomeMenu />

  </div>
)

export default IndexPage

import React, {useState} from "react";
import styled from "@emotion/styled";

import {useTransition, useSpring, animated, config} from 'react-spring'

import {
  Name,
  ContactItems,
  MenuItems
} from './home_menu_components.js'

const SHomeMenu = styled(animated.div)`
  background: white; height: 600px;
  width: 500px;

  position: absolute;
  margin-top: 40px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: block;
  margin: auto;

  border-radius: 8px;

  text-align: center;
  font-family: 'IBM Plex Mono', monospace;

  @media(max-width: 520px){
    width: 80%;
    height: 70%;
  }

  box-shadow: 0px 0px 30px 2px #97ead2;
  transition: box-shadow 0.5s;
  will-change: transform;

  :hover{
    box-shadow: 0px 0px 50px -2px #97ead2;
  }
`;

const SMenuList = styled(animated.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  font-family: 'IBM Plex Mono', monospace;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MenuLink = styled.a`
  display: block;
  font-size: 20px;
  margin: 20px 0;
  color: black;
  text-decoration: none;
  font-family: 'IBM Plex Mono', monospace;

  :hover{
    text-decoration: none;
    color: #420072;
  }

`;

const MenuContact = styled.p`
  display: block;
  color: black;
  font-size: 20px;
  margin: 20px 0;
  :hover{
    color: #420072;
    cursor: pointer;
  }
`;

const UnderConstruction = styled.p`
  display: block;
  color: black;
  font-size: 16px;
  margin: 20px 0;
  position: absolute;
  bottom: 30px;
  right: 0;
  left: 0;
`;



const HomeMenu = ({}) => {
  const [toggle, set] = useState(false);

  const transitions = useTransition(toggle, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.wobbly,
  });

  const RenderMenuItem = ({title, link}) => {
    return(
      <MenuLink href={link}>
        {title}
      </MenuLink>
    );
  };


  const calc = (x, y) => [-(y - window.innerHeight / 2) / 350, (x - window.innerWidth / 2) / 350, 1.01]
  const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

  const [props2, set2] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))



  return(
    <SHomeMenu   className="card"
      onMouseMove={({ clientX: x, clientY: y }) => set2({ xys: calc(x, y) })}
      onMouseLeave={() => set2({ xys: [0, 0, 1] })}
      style={{ transform: props2.xys.interpolate(trans) }}   >

      <Name onClick={() => set(!toggle)}>Sophia Deak</Name>
      {transitions.map(({item, key, props}) => 
        (
          !item?
          <SMenuList style={props}>
            {MenuItems.map(RenderMenuItem)}
            <MenuContact onClick={() => set(true)} >Contact </MenuContact> 
            <UnderConstruction>
              (cool new content coming soon)
            </UnderConstruction>

          </SMenuList>
          : 
          <SMenuList style={props}>
            {ContactItems.map(RenderMenuItem)}
          </SMenuList>
        )
      )}
    </SHomeMenu>
  );
}

export default HomeMenu;
